:root {
  --background-color-alt: transparent;
}
body.app-body.flavour-glitch > #mastodon .compose-form__autosuggest-wrapper > :last-child {
  padding-bottom: 2em !important;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons {
  width: 100%;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons > div:last-child {
  margin-inline-start: auto;
}
body.app-body.flavour-glitch > #mastodon .compose-form__buttons-wrapper {
  position: relative;
}
body.app-body.flavour-glitch > #mastodon .character-counter__wrapper {
  position: absolute;
  inset-inline-end: 0;
  bottom: 100%;
  margin: 4px 4px;
  border-radius: 6px;
  padding: 0.1em 0.5em;
}
body.app-body.flavour-glitch > #mastodon .character-counter__wrapper span {
  font-size: 0.9em;
  font-weight: 500;
}
body.app-body.flavour-glitch > #mastodon .compose-form__highlightable {
  margin-bottom: 50px !important;
}
body.app-body.flavour-glitch > #mastodon .compose-form__submit {
  position: absolute;
  right: -12px;
  top: 100%;
  margin-top: 24px;
}
body.app-body.flavour-glitch > #mastodon .compose-form__submit button {
  padding: 10px 20px;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content {
  height: auto !important;
  overflow: visible;
  mask: unset !important;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content .status__content__text {
  mask: linear-gradient(to bottom, #000 50px, transparent) !important;
  -webkit-mask: linear-gradient(to bottom, #000 50px, transparent) !important;
  max-height: 100px;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content p:not(:last-child) {
  margin-bottom: 0.4em;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content br {
  display: block;
}
body.app-body.flavour-glitch > #mastodon .collapsed .status__content::after {
  content: unset;
}
body.app-body.flavour-glitch > #mastodon .collapsed.muted .status__content__text ~ * {
  display: none;
}
@media (pointer: coarse) {
  body.app-body.flavour-glitch > #mastodon .status__info {
    align-items: center;
  }
  body.app-body.flavour-glitch > #mastodon .status__info__icons {
    height: auto;
  }
}
body.app-body.flavour-glitch > #mastodon .status {
  isolation: isolate;
  overflow: hidden;
}
body.app-body.flavour-glitch > #mastodon .status__info {
  pointer-events: none;
}
body.app-body.flavour-glitch > #mastodon .status__avatar,
body.app-body.flavour-glitch > #mastodon .status__info__icons {
  pointer-events: all;
}
body.app-body.flavour-glitch > #mastodon .status > :not(.status__content):not(.status__line) {
  position: relative;
  z-index: 2;
}
body.app-body.flavour-glitch > #mastodon .status:not(.status-direct) > .status__content {
  margin-block: -100px !important;
  padding-block: 100px !important;
}
body.app-body.flavour-glitch > #mastodon .full-width {
  margin-inline: 0 !important;
}
body.app-body.flavour-glitch > #mastodon .media-gallery__item > .media-gallery__preview {
  display: unset;
}
body.app-body.flavour-glitch > #mastodon .status__action-bar-spacer {
  min-width: 5px;
}
body.app-body.flavour-glitch > #mastodon .status__relative-time {
  margin-inline: auto 5px !important;
  z-index: 2;
  flex-grow: 0 !important;
  min-width: 5ch !important;
  max-width: unset !important;
  justify-content: flex-end;
}
body.app-body.flavour-glitch > #mastodon .status__relative-time time {
  display: inline !important;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar {
  width: unset;
  margin-top: 8px;
  pointer-events: none;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button {
  pointer-events: all;
  border-radius: 6px !important;
  padding-block: 2px;
  border: 1px solid var(--border-color-2);
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button .reactions-bar__item__emoji {
  height: 2em;
  width: 2em;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar button:not(.active) {
  background: none;
}
body.app-body.flavour-glitch > #mastodon .reactions-bar:empty {
  display: none;
}
body.app-body.flavour-glitch > #mastodon .notification__message {
  padding-top: 15px;
}
body.app-body.flavour-glitch > #mastodon .notification__message + .status {
  padding-top: 5px !important;
}
body.app-body.flavour-glitch > #mastodon .column-settings__pillbar {
  border-radius: var(--radius);
}
body.app-body.flavour-glitch > #mastodon .pillbar-button {
  padding: 10px;
  border-radius: 4px;
}
body.app-body.flavour-glitch > #mastodon .column-header__notif-cleaning-buttons {
  flex-wrap: wrap;
}
body.app-body.flavour-glitch > #mastodon .column-header__notif-cleaning-buttons button {
  min-width: 50% !important;
}
body.app-body.flavour-glitch > #mastodon .notification__dismiss-overlay {
  position: absolute !important;
}
body.app-body.flavour-glitch > #mastodon .notification__dismiss-overlay .wrappy {
  box-shadow: none;
  background: none;
  border-top: 0;
}
body.app-body.flavour-glitch > #mastodon .local-settings {
  max-height: 700px !important;
  width: 100%;
}
body.app-body.flavour-glitch > #mastodon .glitch.local-settings__page {
  padding: 20px;
}
body.app-body.flavour-glitch > #mastodon .local-settings__navigation {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: none;
  border-right: 1px solid var(--border-color);
  width: auto;
}
body.app-body.flavour-glitch > #mastodon .local-settings__navigation .local-settings__navigation__item:not(.close):not(.active) {
  background: none;
}
body.app-body.flavour-glitch > #mastodon .local-settings__navigation .local-settings__navigation__item {
  border: 0;
  flex-direction: column;
  padding-inline: 8px;
}
body.app-body.flavour-glitch > #mastodon .local-settings__navigation .local-settings__navigation__item span {
  font-size: 0.8em;
}
body.app-body.flavour-glitch > #mastodon .local-settings__navigation [href="/settings/preferences"] {
  margin-block: auto 10px;
  border-radius: var(--radius);
  border: 1px solid var(--border-color);
}